
import React, { createContext, useContext, useState } from 'react';

const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);

export const GlobalStateProvider = ({ children }) => {
  const [listActive, setListActive] = useState(false);

  return (
    <GlobalStateContext.Provider value={{ listActive, setListActive }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
