import axios from "axios";
import tokenUpdate from "./tokenUpdate";

const token = () => {
  let result = localStorage.getItem("1");
  result = JSON.parse(result);
  //console.log(result);
  if (result) {
    return result.token;
  } else {
    return "";
  }
};
const usuarioId = () => {
  let result = localStorage.getItem("2");
  result = JSON.parse(result);
  //console.log(result.usuarioId);
  if (result) {
    //const codigos = ;
    return result.usuarioId;
  } else {
    return "";
  }
};
const api = axios.create({});
// const api = axios.create({
//   baseURL: url,
//   headers: {
//     Authorization: `Bearer ${token()}`,
//     "x-user-id": `${usuarioId()}`,
//   },
// });

api.interceptors.request.use(async (config) => {
  const url = `${
    window?.config?.REACT_APP_BASE_URL ? window?.config?.REACT_APP_BASE_URL : ""
  }`;

  let isPublic = window.location.pathname.includes("/public");
  if (token() || isPublic) {
    try {
      const tokenAtual = token();
      const user = usuarioId();
      config.baseURL = url;
      config.headers.Authorization = tokenAtual ? `Bearer ${tokenAtual}` : "";
      config.headers["x-user-id"] = user ? `${user}` : "";
      // atualiza o token antes de continuar com a requisição
      if (!isPublic) await tokenUpdate(token());
      return config;
    } catch (error) {
      console.error(error);
    }
  } else {
    localStorage.clear();
    window.location.replace("/login");
  }
});

export default api;
