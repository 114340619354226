import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";

export default function CreateArtigoModal({
  socket,
  usuarioId,
  openModalCreate,
  setOpenModalCreate,
  paiId,
  paiValorConteudo,
  logsLoginId,
  isPai,
  callInitAPIs,
  handleTreeItemClick,
}) {
  const [titulo, setTitulo] = useState("");
  const [status, setStatus] = useState(2);
  const [possuiConteudo, setPossuiConteudo] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);

  const handleApiCreateArtigo = () => {
    try {
      setLoadingApi(true);
      const message = {
        titulo,
        status,
        possuiConteudo,
        logsLoginId,
        usuarioId,
        artigoPaiId: isPai ? undefined : paiId,
      };

      socket.emit("createArtigo", message, (response) => {
        if (response.status === 403) {
          console.error(response.error);
        } else if (response.status !== 201) {
          throw new Error("Erro ao obter dados Socket");
        } else {
          handleClose();
          setLoadingApi(false);
          if (isPai) {
            handleTreeItemClick(response.data.id, possuiConteudo);
          } else {
            handleTreeItemClick(response.data.id, paiValorConteudo);
          }
          callInitAPIs();
        }
      });
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingApi(false);
    }
  };

  const handleClose = () => {
    setOpenModalCreate(false);
    setTitulo("");
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && titulo) {
      handleApiCreateArtigo();
    }
  };

  return (
    <Dialog
      open={openModalCreate}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          color="#5B2E87"
          fontSize="18px"
        >
          {isPai ? "CRIAR ARTIGO" : "ADICIONAR ARTIGO"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="off"
          sx={{
            marginTop: "10px",
            maxWidth: "370px",
            minWidth: "150px",
            width: "70vw",
          }}
          variant="standard"
          label="Título"
          value={titulo}
          onChange={(event) => setTitulo(event.target.value)}
          onKeyUp={handleEnterKeyPress}
        />
      </DialogContent>
      <DialogActions sx={{ position: "relative" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="text"
          color={"secondary"}
        >
          CANCELAR
        </Button>
        <Button
          onClick={() => handleApiCreateArtigo()}
          variant="text"
          color={"primary"}
          disabled={!titulo || loadingApi}
        >
          ADICIONAR
        </Button>
        {loadingApi && (
          <CircularProgress
            size="1.4rem"
            sx={{ position: "absolute", right: 45 }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
