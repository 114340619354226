import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Container,
  Icon,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Button,
} from "@mui/material";
import mwIcon from "../../assets/logo.png";
import mwIcon2 from "../../assets/Logo_MW-09.png";

import { Outlet, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useGlobalState } from "./GlobalStateContext";

const styles = {
  logo: {
    width: "226px",
    height: "auto",
    marginTop: " 0px",
    marginLeft: "6px",
  },
  logo2: {
    width: "80px",
    height: "auto",
    marginTop: " 0px",
    marginLeft: "0px",
  },
};

function Layout(params) {
  const { colorMode, setColorMode } = params;
  const [pageValue, setPageValeu] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [opacity, setOpacity] = useState("0%");
  const [baseImage, setBaseImage] = useState("");
  const [administrador, setAdministrador] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  //notificacoes
  const [countNotificacoes, setCountNotificacoes] = useState(0);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const isFold = useMediaQuery("(max-width:300px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTableteMini = useMediaQuery("(max-width:800px)");
  const isTablete = useMediaQuery("(max-width:900px)");
  const isTabletePro = useMediaQuery("(max-width:1160px)");
  const { listActive, setListActive } = useGlobalState();

  const handleLogin = () => {
    navigate("/login");
  };

  const navigate = useNavigate();

  return (
    <Box>
      <Box>
        {isMobile || isTableteMini || isTablete || isTabletePro ? (
          <AppBar
            position="fixed"
            sx={{
              top: 0,
            }}
            elevation={0}
            // color="primary"
          >
            <Container maxWidth="100vw">
              <Toolbar sx={{ width: "98vw", height: 50 }}>
                <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "100%",
                      width: isFold
                        ? "60%"
                        : isMobile
                        ? "40%"
                        : isTableteMini
                        ? "18%"
                        : isTablete
                        ? "16%"
                        : isTabletePro
                        ? "13%"
                        : "35%",
                      marginLeft: "-30px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setListActive(!listActive);
                      }}
                    >
                      <Icon>
                        <MenuIcon sx={{ color: "#ffffff" }} />
                      </Icon>
                    </IconButton>

                    <IconButton href={"/"} sx={{ width: "50px" }}>
                      <Icon style={styles.logo2}>
                        <img
                          src={mwIcon2}
                          style={styles.logo2}
                          alt={"mw-logo"}
                        />
                      </Icon>
                    </IconButton>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 62,
                      width: "90%",
                      gap: "20px",
                    }}
                  >
                    {" "}
                    <Tooltip title="Fazer login">
                      <Button
                        variant="outlined"
                        onClick={handleLogin}
                        sx={{
                          color: "#ffffff",
                          /* backgroundColor: "#341a4dae", */
                          "&:hover": {
                            backgroundColor: "#341a4d28",
                          },
                        }}
                      >
                        Entrar
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>
              </Toolbar>
            </Container>
          </AppBar>
        ) : (
          <AppBar
            position="fixed"
            sx={{
              top: 0,
            }}
            elevation={0}
            // color="primary"
          >
            <Container maxWidth="100vw">
              <Toolbar sx={{ width: "98vw", height: 80 }}>
                <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                  <IconButton
                    href={"/"}
                    sx={{ padding: 0, marginLeft: "-15px" }}
                  >
                    <Icon style={styles.logo}>
                      <img src={mwIcon} style={styles.logo} alt={"mw-logo"} />
                    </Icon>
                  </IconButton>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 62,
                      width: "90%",
                      gap: "20px",
                    }}
                  >
                    {" "}
                    <Tooltip title="Fazer login">
                      <Button
                        variant="outlined"
                        onClick={handleLogin}
                        sx={{
                          color: "#ffffff",
                          /* backgroundColor: "#341a4dae", */
                          "&:hover": {
                            backgroundColor: "#341a4d28",
                          },
                        }}
                      >
                        Entrar
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>
              </Toolbar>
            </Container>
          </AppBar>
        )}
        <Box sx={{ height: "80px", width: "99vw" }} />
        <Stack direction={"row"} sx={{ backgroundColor: "drawer.main" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,

              borderTopLeftRadius: 8,
              backgroundColor: "background.default",
            }}
          >
            <Box>
              <Outlet />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default Layout;
