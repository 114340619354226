import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
} from "@mui/material";

export default function AlertArtigoSaveModal({
  openModalAlertSave,
  setOpenModalAlertSave,
  valorModalAlertSave,
  handleTreeItemClickModalAlert,
  handleSelectedItemsChange,
}) {
  const handleClose = () => {
    setOpenModalAlertSave(false);
    handleSelectedItemsChange(null, [valorModalAlertSave[3]]);
  };

  return (
    <Dialog
      open={openModalAlertSave}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          color="#AF2121"
          fontSize="22px"
          textAlign="center"
        >
          ATENÇÃO!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          O conteúdo do artigo não foi salvo. Se você prosseguir com a operação
          os dados modificados serão perdidos permanentemente!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleSelectedItemsChange(null, [valorModalAlertSave[3]]);
            setOpenModalAlertSave(false);
          }}
          variant="text"
          sx={{ color: "#5B2E87" }}
        >
          CANCELAR
        </Button>
        <Button
          onClick={() => {
            setOpenModalAlertSave(false);
            handleTreeItemClickModalAlert(
              valorModalAlertSave[0],
              valorModalAlertSave[1],
              valorModalAlertSave[2]
            );
          }}
          variant="text"
          sx={{ color: "#AF2121", marginRight: "2px" }}
        >
          SAIR SEM SALVAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
