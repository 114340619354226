import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";

export default function DeleteArtigoModal({
  socket,
  usuarioId,
  openModalDelete,
  setOpenModalDelete,
  id,
  conteudoAbertoId,
  handleTreeItemClick,
  valorConteudoAberto,
  handleToggle,
  setConteudo,
  relacionamentoFilho,
  logsLoginId,
  callInitAPIs,
}) {
  const [loadingApi, setLoadingApi] = useState(false);

  async function handleApiDeleteArtigo() {
    try {
      setLoadingApi(true);
      const message = {
        id,
        logsLoginId,
        usuarioId,
      };

      socket.emit("deleteArtigoWiki", message, (response) => {
        if (response.status === 403) {
          console.error(response.error);
        } else if (response.status !== 200) {
          throw new Error("Erro ao obter dados Socket");
        } else {
          handleClose();
          setLoadingApi(false);
          callInitAPIs();
          let idsFilhos = relacionamentoFilho
            ?.map((filho) => filho.id)
            .reverse();
          if (id.includes(conteudoAbertoId)) {
            setConteudo("");
            handleToggle(null, []);
          } else if (id.some((ids) => idsFilhos.includes(ids))) {
            handleTreeItemClick(conteudoAbertoId, valorConteudoAberto);
          }
        }
      });
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingApi(false);
    }
  }

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  return (
    <Dialog
      open={openModalDelete}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          color="#AF2121"
          fontSize="18px"
        >
          DESEJA MESMO EXCLUIR?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Se você prosseguir com a operação o item será deletado e os dados
          apagados serão perdidos permanentemente!
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ position: "relative" }}>
        <Button
          onClick={() => {
            handleClose();
          }}
          variant="text"
          sx={{ color: "#5B2E87", marginRight: "2px" }}
        >
          CANCELAR
        </Button>
        <Button
          onClick={() => {
            handleApiDeleteArtigo();
          }}
          variant="text"
          sx={{ color: "#AF2121" }}
          disabled={loadingApi}
        >
          EXCLUIR
        </Button>
        {loadingApi && (
          <CircularProgress
            size="1.4rem"
            sx={{ position: "absolute", right: 32 }}
            color="error"
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
