import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { useState } from "react";

export default function EditArtigoModal({
  socket,
  usuarioId,
  openModalEdit,
  setOpenModalEdit,
  id,
  conteudoAbertoId,
  valorConteudoAberto,
  relacionamentoFilho,
  handleTreeItemClick,
  titulo,
  logsLoginId,
  callInitAPIs,
}) {
  const [novoTituloTopico, setNovoTituloTopico] = useState("");
  const [loadingApi, setLoadingApi] = useState(false);

  async function handleApiEditArtigo() {
    try {
      setLoadingApi(true);
      const message = {
        id,
        titulo: novoTituloTopico,
        logsLoginId,
        usuarioId,
      };
      socket.emit("updateArtigoWiki", message, (response) => {
        if (response.status === 403) {
          console.error(response.error);
        } else if (response.status !== 200) {
          throw new Error("Erro ao obter dados da API");
        } else {
          handleClose();
          setLoadingApi(false);
          callInitAPIs();
          let idsFilhos = relacionamentoFilho
            ?.map((filho) => filho.id)
            .reverse();
          if (conteudoAbertoId === id) {
            handleTreeItemClick(id, valorConteudoAberto);
          } else if (idsFilhos.includes(id)) {
            handleTreeItemClick(conteudoAbertoId, valorConteudoAberto);
          }
        }
      });
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingApi(false);
    }
  }

  const handleClose = () => {
    setOpenModalEdit(false);
    setNovoTituloTopico("");
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && novoTituloTopico) {
      handleApiEditArtigo();
    }
  };

  return (
    <Dialog
      open={openModalEdit}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          color="#5B2E87"
          fontSize="18px"
        >
          RENOMEAR TÓPICO
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoComplete="off"
          sx={{
            marginTop: "10px",
            maxWidth: "370px",
            minWidth: "150px",
            width: "70vw",
          }}
          variant="standard"
          label="Título"
          defaultValue={titulo}
          onChange={(event) => setNovoTituloTopico(event.target.value)}
          onKeyUp={handleEnterKeyPress}
        />
      </DialogContent>
      <DialogActions sx={{ position: "relative" }}>
        <Button
          onClick={() => {
            setOpenModalEdit(false);
            handleClose();
          }}
          variant="text"
          sx={{ color: "#AF2121" }}
        >
          CANCELAR
        </Button>
        <Button
          onClick={() => {
            handleApiEditArtigo();
          }}
          variant="text"
          sx={{ color: "#5B2E87" }}
          disabled={
            !novoTituloTopico ||
            novoTituloTopico.trim() === titulo ||
            loadingApi
          }
        >
          ADICIONAR
        </Button>
        {loadingApi && (
          <CircularProgress
            size="1.4rem"
            sx={{ position: "absolute", right: 45 }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
