import React, { useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import checkToken from "../../services/CheckToken";
import mwIcon from "../../assets/logo.png";
import mwIcon2 from "../../assets/Logo_MW-09.png";

import { Outlet, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useGlobalState } from "../layoutPublica/GlobalStateContext";

const styles = {
  logo: {
    width: "226px",
    height: "auto",
    marginTop: " 0px",
    marginLeft: "6px",
  },
  logo2: {
    width: "80px",
    height: "auto",
    marginTop: " 0px",
    marginLeft: "0px",
  },
};

function Layout(params) {
  const { socket, setCheckLogin } = params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [baseImage, setBaseImage] = useState("");
  const isFold = useMediaQuery("(max-width:300px)");
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTableteMini = useMediaQuery("(max-width:800px)");
  const isTablete = useMediaQuery("(max-width:900px)");
  const isTabletePro = useMediaQuery("(max-width:1160px)");
  const { listActive, setListActive } = useGlobalState();

  const opacity = "0%";

  const navigate = useNavigate();
  //notificacoes

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(true);
      tokenValidate();
    }, 200);

    async function tokenValidate() {
      const data = await checkToken();

      if (!data) {
        navigate("/login");
      }
      setCheckLogin(true);
    }
  }, []);

  useEffect(() => {
    const handleUsuarioId = async () => {
      let result = localStorage.getItem("2");
      result = JSON.parse(result);

      const pathname = window.location.pathname;
      if (!result) {
        window.location.replace("/");
      } else {
        if (
          result.codigoPermissao &&
          (result.codigoPermissao.indexOf("adm001") !== -1 ||
            result.codigoPermissao.indexOf("adm002") !== -1 ||
            result.codigoPermissao.indexOf("wiki001") !== -1)
        ) {
          if (pathname == "/private" || pathname == "/private/") {
            // window.location.replace("/private/adm");
          }
        } else {
          if (pathname == "/private" || pathname == "/private/") {
            // window.location.replace("/private/user");
          }
        }
        if (result) {
          setBaseImage(result.foto);
        } else {
          return "";
        }
      }
    };
    handleUsuarioId();
  }, []);

  const handleLogout = () => {
    socket.disconnect();
    localStorage.clear();
    navigate("/login");
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <Box>
        {!loadingPage && (
          <Box
            sx={{
              m: 1,
              position: "relative",
              marginLeft: "50%",
              marginTop: "20%",
              opacity: `${opacity}`,
            }}
          >
            <Icon style={{ width: "80px", height: "auto" }}>
              <img src={mwIcon} style={{ width: "80px", height: "auto" }} />
            </Icon>

            <CircularProgress
              size={100}
              sx={{
                color: "primary",
                position: "absolute",
                top: -9,
                left: -6,
                zIndex: 1,
              }}
              thickness={2.5}
            />
          </Box>
        )}
        {loadingPage && (
          <>
            <Box>
              {isMobile || isTableteMini || isTablete || isTabletePro ? (
                <AppBar
                  position="fixed"
                  sx={{
                    top: 0,
                  }}
                  elevation={0}
                  // color="primary"
                >
                  <Container maxWidth="100vw">
                    <Toolbar sx={{ width: "98vw", height: 50 }}>
                      <Stack
                        direction={"row"}
                        width={"100%"}
                        alignItems={"center"}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "100%",
                            width: isFold
                              ? "60%"
                              : isMobile
                              ? "40%"
                              : isTableteMini
                              ? "18%"
                              : isTablete
                              ? "16%"
                              : isTabletePro
                              ? "13%"
                              : "35%",
                            marginLeft: "-30px",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setListActive(!listActive);
                            }}
                          >
                            <Icon>
                              <MenuIcon sx={{ color: "#ffffff" }} />
                            </Icon>
                          </IconButton>

                          <IconButton href={"/"} sx={{ width: "50px" }}>
                            <Icon style={styles.logo2}>
                              <img
                                src={mwIcon2}
                                style={styles.logo2}
                                alt={"mw-logo"}
                              />
                            </Icon>
                          </IconButton>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            height: 62,
                            width: "90%",
                            gap: "20px",
                          }}
                        >
                          {" "}
                          <Tooltip title="Abrir Opções">
                            <IconButton onClick={handleClick}>
                              <Avatar src={baseImage} />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            disableScrollLock={true}
                            sx={{ marginLeft: "-5px" }}
                          >
                            <MenuItem onClick={handleLogout}>
                              <ListItemIcon>
                                <Logout />
                              </ListItemIcon>
                              <ListItemText>Sair</ListItemText>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Stack>
                    </Toolbar>
                  </Container>
                </AppBar>
              ) : (
                <AppBar
                  position="fixed"
                  sx={{
                    top: 0,
                  }}
                  elevation={0}
                  // color="primary"
                >
                  <Container maxWidth="100vw">
                    <Toolbar sx={{ width: "98vw", height: 80 }}>
                      <Stack
                        direction={"row"}
                        width={"100%"}
                        alignItems={"center"}
                      >
                        <IconButton
                          href={"/private"}
                          sx={{ padding: 0, marginLeft: "-15px" }}
                        >
                          <Icon style={styles.logo}>
                            <img
                              src={mwIcon}
                              style={styles.logo}
                              alt={"mw-logo"}
                            />
                          </Icon>
                        </IconButton>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            height: 62,
                            width: "90%",
                            gap: "20px",
                          }}
                        >
                          {" "}
                          <Tooltip title="Abrir Opções">
                            <IconButton onClick={handleClick}>
                              <Avatar src={baseImage} />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            disableScrollLock={true}
                            sx={{ marginLeft: "-5px" }}
                          >
                            <MenuItem onClick={handleLogout}>
                              <ListItemIcon>
                                <Logout />
                              </ListItemIcon>
                              <ListItemText>Sair</ListItemText>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </Stack>
                    </Toolbar>
                  </Container>
                </AppBar>
              )}

              <Box sx={{ height: "80px", width: "99vw" }} />
              <Stack direction={"row"} sx={{ backgroundColor: "drawer.main" }}>
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,

                    borderTopLeftRadius: 8,
                    backgroundColor: "background.default",
                  }}
                >
                  <Box>
                    <Outlet />
                  </Box>
                </Box>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Layout;
