import {
  Add,
  ExpandCircleDown,
  ExpandCircleDownOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  Public,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PublicIcon from "@mui/icons-material/Public";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Check from "@mui/icons-material/Check";
import { TreeItem } from "@mui/x-tree-view";
import { useState } from "react";
import { useGlobalState } from "../../pages/layoutPublica/GlobalStateContext";

const styles = {
  boxOpcoesMenuStatus: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxLabel: {
    /* backgroundColor: "blue", */
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "48px",
    maxHeight: "auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

export default function WikiItem(props) {
  const {
    socket,
    usuarioId,
    nodes,
    renderTree,
    nodePai,
    setNodePai,
    setArrayCascata,
    arrayCascata,
    filhosIdsCascata,
    artigos,
    findParentNodeInTree,
    setIsPai,
    setTituloRenomearMenu,
    setConteudoMenu,
    setOpenModalCreate,
    setOpenModalEdit,
    setOpenModalDelete,
    setMenuId,
    callInitAPIs,
    handleToggle,
    expandedNodes,
    handleTreeItemClick,
    logsLoginId,
    editable,
    conteudoAbertoId,
  } = props;

  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElConteudo, setAnchorElConteudo] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [loadingApiStatus, setLoadingApiStatus] = useState({});
  const [loadingApiAtivarConteudo, setLoadingApiAtivarConteudo] =
    useState(false);
  const [loadingApiDesativarConteudo, setLoadingApiDesativarConteudo] =
    useState(false);
  const isMobile = useMediaQuery("(max-width:1160px)");
  const { listActive, setListActive } = useGlobalState();
  const arrayIrmaos = nodePai?.artigosFilho || artigos;

  async function handleApiDefinirStatus(status, id) {
    try {
      setLoadingApiStatus((prevStatus) => ({
        ...prevStatus,
        [status]: true,
      }));

      const message = {
        id,
        status,
        logsLoginId,
        usuarioId,
      };

      socket.emit("updateArtigoStatusWiki", message, (response) => {
        if (response.status === 403) {
          console.error(response.error);
        } else if (response.status !== 200) {
          throw new Error("Erro ao obter dados da API");
        } else {
          handleClose();
          setLoadingApiStatus((prevStatus) => ({
            ...prevStatus,
            [status]: false,
          }));
          callInitAPIs();
        }
      });
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingApiStatus((prevStatus) => ({
        ...prevStatus,
        [status]: false,
      }));
    }
  }
  async function handleApiMudarPosicao(idIrmaoAnterior, idIrmaoPosterior) {
    try {
      const message = {
        idIrmaoAnterior,
        idIrmaoPosterior,
        logsLoginId,
        usuarioId,
      };
      socket.emit("updatePositionArtigoWiki", message, (response) => {
        if (response.status === 403) {
          console.error(response.error);
        } else if (response.status !== 200) {
          throw new Error("Erro ao obter dados da API");
        } else {
          handleClose();
          callInitAPIs();
        }
      });
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    }
  }
  //chama a api para ATIVAR ou DESATIVAR o conteúdo de um artigo
  async function handleApiValorConteudo(valor, id) {
    try {
      if (valor) {
        setLoadingApiAtivarConteudo(true);
      } else {
        setLoadingApiDesativarConteudo(true);
      }

      const message = {
        id,
        possuiConteudo: valor,
        logsLoginId,
        usuarioId,
      };
      const message2 = {
        id,
        logsLoginId,
        usuarioId,
      };

      //Mudara o status do artigo para possui conteúdo ou não
      socket.emit("updateArtigoWiki", message, (response) => {
        if (response.status === 403) {
          console.error(response.error);
        } else if (response.status !== 200) {
          throw new Error("Erro ao obter dados Socket");
        } else {
          //Criará o conteúdo do artigo caso não tenha
          if (valor) {
            socket.emit("createConteudoWiki", message2, (response) => {
              if (response.status === 403) {
                console.error(response.error);
              } else if (
                valor &&
                response.status !== 201 &&
                response.status !== 200
              ) {
                throw new Error("Erro ao obter dados Socket");
              } else {
                handleClose();
                setLoadingApiAtivarConteudo(false);
                setLoadingApiDesativarConteudo(false);
                callInitAPIs();
                handleTreeItemClick(nodes?.id, valor);
              }
            });
          } else {
            handleClose();
            setLoadingApiAtivarConteudo(false);
            setLoadingApiDesativarConteudo(false);
            callInitAPIs();
            handleTreeItemClick(nodes?.id, valor);
          }
        }
      });
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingApiAtivarConteudo(false);
      setLoadingApiDesativarConteudo(false);
    }
  }

  const handleLeftClick = (event, node) => {
    event.preventDefault();
    setMenuId(node.id);
    setTituloRenomearMenu(node.titulo);
    setConteudoMenu(node.possuiConteudo);
    let idsCascate = filhosIdsCascata(node);
    setArrayCascata(idsCascate);
    let parentNode = findParentNodeInTree(node.id, artigos);
    setNodePai(parentNode || []);
    event.preventDefault(); // Prevent the default context menu
    event.stopPropagation();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            nodeId: node.id,
          }
        : // Close menu if already open
          null
    );
  };

  const handleStatusMenuClick = (event) => {
    setAnchorElStatus(event.currentTarget);
  };
  const handleConteudoMenuClick = (event) => {
    setAnchorElConteudo(event.currentTarget);
  };

  const handleCloseSubmenu = () => {
    setAnchorElStatus(null);
    setAnchorElConteudo(null);
  };

  const handleClose = () => {
    setContextMenu(null);
    setAnchorElStatus(null);
    setAnchorElConteudo(null);
  };

  const Label = (nodes) => (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={styles.boxLabel}
        onClick={() => {
          if (conteudoAbertoId !== nodes?.id) {
            handleTreeItemClick(nodes?.id, nodes?.possuiConteudo);
            if (isMobile && listActive) {
              setListActive(false);
            }
          }
        }}
      >
        <Stack
          direction={"row"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          gap={0.5}
        >
          <Typography
            fontSize={"15px"}
            sx={{
              fontWeight: 500,
              overflowWrap: "break-word",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              hyphens: "auto",
            }}
          >
            {nodes?.titulo}
          </Typography>

          {nodes?.status === 0 && editable && (
            <Public fontSize="small" color="primary" />
          )}
          {nodes?.status === 1 && editable && (
            <Visibility fontSize="small" color="primary" />
          )}
          {nodes?.status === 2 && editable && (
            <VisibilityOff fontSize="small" color="primary" />
          )}
        </Stack>
      </Box>
      {nodes?.artigosFilho?.length > 0 && (
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            left: "-38px",
            transform: "translateY(-50%)",
            zIndex: 99,
          }}
          onClick={(event) => {
            let paisId = nodes?.artigosPai.map((pai) => pai.id).reverse() || [];
            if (expandedNodes.includes(nodes?.id)) {
              handleToggle(null, [...paisId]);
              event.stopPropagation();
            } else {
              handleToggle(null, [nodes?.id, ...paisId]);
              event.stopPropagation();
            }
          }}
        >
          <ExpandCircleDown
            color="primary"
            key={expandedNodes.includes(nodes?.id) ? "expanded" : "collapsed"}
            sx={{
              transform: expandedNodes.includes(nodes?.id)
                ? ""
                : "rotate(-90deg)",
            }}
          />
        </IconButton>
      )}
    </Box>
  );

  return (
    <Box
      sx={{ cursor: "context-menu", position: "relative" }}
      onContextMenu={(event) => {
        handleLeftClick(event, nodes);
      }}
      key={`box-${nodes?.id}`}
    >
      <TreeItem key={nodes?.id} nodeId={nodes?.id} label={<Label {...nodes} />}>
        {Array.isArray(nodes?.artigosFilho)
          ? nodes?.artigosFilho.map((node) => renderTree(node))
          : null}
      </TreeItem>

      {editable && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          sx={{ background: "transparent" }}
        >
          <MenuList>
            <MenuItem
              onClick={(event) => {
                setOpenModalCreate(true);
                setIsPai(false);
                handleClose();
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <Add fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Adicionar Subtópico</ListItemText>
            </MenuItem>
            <Divider />

            <MenuItem
              onClick={(event) => {
                handleConteudoMenuClick(event);
              }}
            >
              <ListItemIcon>
                <DescriptionIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Conteúdo</ListItemText>
              <Box sx={{ display: "flex", alignItems: "center", width: "5%" }}>
                <ListItemIcon>
                  <ChevronRightIcon fontSize="small" color="primary" />
                </ListItemIcon>
              </Box>
            </MenuItem>

            <Menu
              open={anchorElConteudo !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              MenuListProps={{ onMouseLeave: handleCloseSubmenu }}
              anchorPosition={
                anchorElConteudo
                  ? {
                      top: anchorElConteudo.getBoundingClientRect().bottom - 44,
                      left: anchorElConteudo.getBoundingClientRect().right,
                    }
                  : undefined
              }
              sx={{ background: "transparent" }}
            >
              <Stack sx={{ position: "relative" }}>
                <MenuItem
                  disabled={loadingApiAtivarConteudo}
                  onClick={(event) => {
                    if (!nodes?.possuiConteudo) {
                      handleApiValorConteudo(true, nodes?.id);
                      event.stopPropagation();
                    }
                  }}
                >
                  <ListItemText
                    sx={{
                      color: nodes?.possuiConteudo ? "primary.main" : "",
                    }}
                  >
                    {nodes?.possuiConteudo ? "Ativado" : "Ativar"}
                  </ListItemText>
                  {nodes?.possuiConteudo && (
                    <Typography variant="body2" color="primary">
                      <Check fontSize="small" sx={{ ml: "5px" }} />
                    </Typography>
                  )}
                </MenuItem>
                {loadingApiAtivarConteudo && (
                  <CircularProgress
                    size="1.4rem"
                    sx={{ position: "absolute", right: 50, bottom: 6 }}
                  />
                )}
              </Stack>
              <Stack sx={{ position: "relative" }}>
                <MenuItem
                  disabled={loadingApiDesativarConteudo}
                  onClick={(event) => {
                    if (nodes?.possuiConteudo) {
                      handleApiValorConteudo(false, nodes?.id);
                      event.stopPropagation();
                    }
                  }}
                >
                  <ListItemText
                    sx={{ color: nodes?.possuiConteudo ? "" : "primary.main" }}
                  >
                    {nodes?.possuiConteudo ? "Desativar" : "Desativado"}
                  </ListItemText>
                  {!nodes?.possuiConteudo && (
                    <Typography variant="body2" color="primary">
                      <Check fontSize="small" sx={{ ml: "5px" }} />
                    </Typography>
                  )}
                </MenuItem>
                {loadingApiDesativarConteudo && (
                  <CircularProgress
                    size="1.4rem"
                    sx={{ position: "absolute", right: 50, bottom: 6 }}
                  />
                )}
              </Stack>
            </Menu>

            <MenuItem
              onClick={(event) => {
                handleStatusMenuClick(event);
              }}
            >
              <ListItemIcon>
                {nodes?.status === 0 && (
                  <Public fontSize="small" color="primary" />
                )}
                {nodes?.status === 1 && (
                  <Visibility fontSize="small" color="primary" />
                )}
                {nodes?.status === 2 && (
                  <VisibilityOff fontSize="small" color="primary" />
                )}
              </ListItemIcon>
              <ListItemText>Status</ListItemText>
              <Box sx={{ display: "flex", alignItems: "center", width: "5%" }}>
                <ListItemIcon>
                  <ChevronRightIcon fontSize="small" color="primary" />
                </ListItemIcon>
              </Box>
            </MenuItem>

            <Menu
              open={anchorElStatus !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              MenuListProps={{ onMouseLeave: handleCloseSubmenu }}
              anchorPosition={
                anchorElStatus
                  ? {
                      top: anchorElStatus.getBoundingClientRect().bottom - 38,
                      left: anchorElStatus.getBoundingClientRect().right,
                    }
                  : undefined
              }
              sx={{ background: "transparent" }}
            >
              <Stack sx={{ position: "relative" }}>
                <MenuItem
                  disabled={
                    nodePai.status === 1 ||
                    nodePai.status === 2 ||
                    loadingApiStatus[0]
                  }
                  onClick={(event) => {
                    if (nodes?.status !== 0) {
                      handleApiDefinirStatus(0, arrayCascata);
                      event.stopPropagation();
                    }
                  }}
                >
                  <ListItemIcon>
                    <PublicIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  {nodes?.status === 0 ? (
                    <Box sx={styles.boxOpcoesMenuStatus}>
                      <ListItemText sx={{ color: "primary.main" }}>
                        Público
                      </ListItemText>
                      <Typography variant="body2" color="primary">
                        <Check fontSize="small" sx={{ ml: "5px" }} />
                      </Typography>
                    </Box>
                  ) : (
                    <ListItemText>Público</ListItemText>
                  )}
                </MenuItem>
                {loadingApiStatus[0] && (
                  <CircularProgress
                    size="1.4rem"
                    sx={{ position: "absolute", right: 58, bottom: 6 }}
                  />
                )}
              </Stack>
              <Stack sx={{ position: "relative" }}>
                <MenuItem
                  disabled={nodePai.status === 2 || loadingApiStatus[1]}
                  onClick={(event) => {
                    if (nodes?.status !== 1) {
                      handleApiDefinirStatus(1, arrayCascata);
                      event.stopPropagation();
                    }
                  }}
                >
                  <ListItemIcon>
                    <VisibilityIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  {nodes?.status === 1 ? (
                    <Box sx={styles.boxOpcoesMenuStatus}>
                      <ListItemText sx={{ color: "primary.main" }}>
                        Privado
                      </ListItemText>
                      <Typography variant="body2" color="primary">
                        <Check fontSize="small" sx={{ ml: "5px" }} />
                      </Typography>
                    </Box>
                  ) : (
                    <ListItemText>Privado</ListItemText>
                  )}
                </MenuItem>
                {loadingApiStatus[1] && (
                  <CircularProgress
                    size="1.4rem"
                    sx={{ position: "absolute", right: 58, bottom: 6 }}
                  />
                )}
              </Stack>
              <Stack sx={{ position: "relative" }}>
                <MenuItem
                  disabled={loadingApiStatus[2]}
                  onClick={(event) => {
                    if (nodes?.status !== 2) {
                      handleApiDefinirStatus(2, arrayCascata);
                      event.stopPropagation();
                    }
                  }}
                >
                  <ListItemIcon>
                    <VisibilityOffIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  {nodes?.status === 2 ? (
                    <Box sx={styles.boxOpcoesMenuStatus}>
                      <ListItemText sx={{ color: "primary.main" }}>
                        Inativo
                      </ListItemText>
                      <Typography variant="body2" color="primary">
                        <Check fontSize="small" sx={{ ml: "5px" }} />
                      </Typography>
                    </Box>
                  ) : (
                    <ListItemText>Inativo</ListItemText>
                  )}
                </MenuItem>
                {loadingApiStatus[2] && (
                  <CircularProgress
                    size="1.4rem"
                    sx={{ position: "absolute", right: 58, bottom: 6 }}
                  />
                )}
              </Stack>
            </Menu>
            <MenuItem
              onClick={(event) => {
                setOpenModalEdit(true);
                handleClose();
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" sx={{ color: "primary.main" }} />
              </ListItemIcon>
              <ListItemText>Renomear</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={
                !arrayIrmaos.findLast(({ posicao }) => posicao < nodes?.posicao)
                  ?.id
              }
              onClick={(event) => {
                handleApiMudarPosicao(
                  arrayIrmaos.findLast(
                    ({ posicao }) => posicao < nodes?.posicao
                  )?.id,
                  nodes?.id
                );
                handleClose();
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <KeyboardArrowUpOutlined
                  fontSize="small"
                  sx={{ color: "primary.main" }}
                />
              </ListItemIcon>
              <ListItemText>Mover pra cima</ListItemText>
            </MenuItem>
            <MenuItem
              disabled={
                !arrayIrmaos.find(({ posicao }) => posicao > nodes?.posicao)?.id
              }
              onClick={(event) => {
                handleApiMudarPosicao(
                  nodes?.id,
                  arrayIrmaos.find(({ posicao }) => posicao > nodes?.posicao)
                    ?.id
                );
                handleClose();
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <KeyboardArrowDownOutlined
                  fontSize="small"
                  sx={{ color: "primary.main" }}
                />
              </ListItemIcon>
              <ListItemText>Mover pra baixo</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                setOpenModalDelete(true);
                handleClose();
                event.stopPropagation();
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText sx={{ color: "red" }}>Excluir</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Box>
  );
}
