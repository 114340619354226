import { Box, Button, Divider, Typography } from "@mui/material";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const styles = {
  textButton: {
    maxWidth: "300px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
};

export default function BotaoBackAndNext(props) {
  const {
    relacionamentoFilho,
    conteudo, //passar so conteudo ID
    artigos,
    findParentNodeInTree,
    handleTreeItemClick,
  } = props;

  let filhoNext = relacionamentoFilho[0];

  let backNode = {};
  let nextNode = {};

  if (conteudo?.id !== artigos[0]?.id) {
    //mudar nome de conteudo
    let loopingbackNode = true; // pode ir para trás
    let arrayIdsLooping = [];

    while (loopingbackNode) {
      let paiArtigoBack = findParentNodeInTree(conteudo.id, artigos);
      // procura por irmãos
      if (paiArtigoBack && conteudo.id) {
        let positionAtualBack = paiArtigoBack?.artigosFilho?.findIndex(
          (position) => position.id === conteudo?.id
        );
        if (positionAtualBack > 0) {
          //se houver nó irmão adiciona ao backNode
          backNode = paiArtigoBack?.artigosFilho[positionAtualBack - 1];

          while (backNode?.artigosFilho?.length > 0) {
            arrayIdsLooping?.push(backNode.id);
            backNode = backNode?.artigosFilho[backNode.artigosFilho.length - 1];
          }
        } else {
          backNode = paiArtigoBack;
        }
        loopingbackNode = false;
      } else if (paiArtigoBack === null) {
        //"nao tem pai"
        let positionRaiz = artigos?.findIndex(
          (artigo) => artigo.id === conteudo?.id
        );

        if (positionRaiz > 0) {
          backNode = artigos[positionRaiz - 1];
          while (backNode?.artigosFilho?.length > 0) {
            arrayIdsLooping?.push(backNode.id);
            backNode = backNode?.artigosFilho[backNode.artigosFilho.length - 1];
          }
        } else {
          backNode = artigos[artigos.length - 1];
        }
      }
      loopingbackNode = false;
    }
  }

  if (!filhoNext && conteudo?.id) {
    let loopingProximoNode = true;
    let conteudoAtualLoopingId = conteudo.id;
    let auxPaiArtigo = {};

    while (loopingProximoNode) {
      let paiArtigo = findParentNodeInTree(conteudoAtualLoopingId, artigos);
      if (paiArtigo) {
        auxPaiArtigo = paiArtigo;
        let positionAtual = paiArtigo?.artigosFilho?.findIndex(
          (position) => position.id === conteudoAtualLoopingId
        );

        if (positionAtual < paiArtigo?.artigosFilho?.length - 1) {
          nextNode = paiArtigo?.artigosFilho[positionAtual + 1];
          loopingProximoNode = false;
        } else {
          conteudoAtualLoopingId = paiArtigo?.id;
        }
      } else if (paiArtigo === null) {
        // Não há mais filhos disponíveis, vamos verificar os irmãos da raiz
        let positionRaiz = artigos?.findIndex(
          (artigo) => artigo.id === auxPaiArtigo?.id
        );

        if (positionRaiz !== -1) {
          if (positionRaiz < artigos?.length - 1) {
            nextNode = artigos[positionRaiz + 1];
          } else {
            nextNode = artigos[0];
          }
        } else {
          let positionRaizArtigo = artigos?.findIndex(
            (artigo) => artigo.id === conteudo?.id
          );

          if (positionRaizArtigo < artigos?.length - 1) {
            nextNode = artigos[positionRaizArtigo + 1];
          } else {
            nextNode = artigos[0];
          }
        }
        loopingProximoNode = false;
      } else {
        loopingProximoNode = false;
      }
    }
  }

  let disabledBackButton = artigos[0]?.id === conteudo?.id;
  let disabledNextButton = artigos[0]?.id === nextNode?.id;

  return (
    <>
      <Divider />
      <Box
        sx={{
          display: "flex",
          width: "100%",

          height: "50px",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: !disabledBackButton ? "space-between" : "flex-end",
          }}
        >
          {!disabledBackButton && (
            <Button
              disabled={disabledBackButton}
              variant="text"
              startIcon={<ArrowBackIosNewOutlinedIcon sx={{ mb: "2.5px" }} />}
              onClick={() => {
                handleTreeItemClick(backNode?.id, backNode?.possuiConteudo);
              }}
            >
              <Typography
                sx={[styles.textButton, { justifyContent: "flex-start" }]}
              >
                Voltar
              </Typography>
            </Button>
          )}
          {!disabledNextButton && (
            <Button
              variant="text"
              endIcon={<ArrowForwardIosIcon sx={{ mb: "2.3px" }} />}
              onClick={() => {
                if (filhoNext) {
                  handleTreeItemClick(filhoNext?.id, filhoNext?.possuiConteudo);
                } else {
                  handleTreeItemClick(nextNode?.id, nextNode?.possuiConteudo);
                }
              }}
            >
              <Typography
                sx={[styles.textButton, { justifyContent: "flex-end" }]}
              >
                {/* {filhoNext?.titulo ? filhoNext?.titulo : nextNode?.titulo} */}
                Próximo
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
      <Divider />
    </>
  );
}
