import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { io } from "socket.io-client";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//import Home from "./pages/home";
import Layout from "./pages/layout";
import LayoutPublica from "./pages/layoutPublica";
import Login from "./pages/login";
//import Acessos from "./pages/Acessos";
//import CriaUsuario from "./pages/criaUsuario";
//import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline } from "@mui/material";
import { GlobalStateProvider } from "../src/pages/layoutPublica/GlobalStateContext";
import WikiEditable from "./pages/wikiEditable";
import WikiOut from "./pages/wikiOut";
function App() {
  //const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");
  const [checkLogin, setCheckLogin] = useState(false);
  const [colorMode, setColorMode] = useState(false);
  /* const [socket, setSocket] = useState(
    io.connect(`${window?.config?.REACT_APP_BASE_URL || ""}`, {
      reconnection: true,
      auth: {
        token: window?.config?.REACT_APP_SOCKET_TOKEN || "",
      },
    })
  ); */
  const [socket, setSocket] = useState(io());

  /* useEffect(() => {
    setColorMode(prefersDarkMode);
  }, [prefersDarkMode]);
  useEffect(() => {
    let colorModeStorage = JSON.parse(localStorage.getItem("3"));
    if (colorModeStorage) {
      setColorMode(colorModeStorage.colorMode);
    }
  }, []); */

  useEffect(() => {
    if (checkLogin) {
      setSocket(
        io.connect(`${window?.config?.REACT_APP_BASE_URL || ""}`, {
          reconnection: true,
          auth: {
            token: window?.config?.REACT_APP_SOCKET_TOKEN || "",
          },
        })
      );
    }
  }, [checkLogin]);

  useEffect(() => {
    if (!checkLogin) return;
    setCheckLogin(false);
    function onConnect() {
      console.log("Socket connection established");
    }
    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }
    socket.on("connect_error", connectErrorEvent);
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    socket.on("connect", onConnect);
    return () => {
      socket.off("connect", onConnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.off("connect", onConnect);
    };
  }, [socket]);

  //temas
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: !colorMode ? "light" : "dark",
          primary: {
            main: !colorMode ? "#5B2E87" : "#A77AD2",
          },
          secondary: {
            main: "#e83750",
          },
          drawer: {
            chat: !colorMode ? "#fff " : "#000000",
          },
          background: {
            default: !colorMode ? "#F0F2F5" : "#141617",
            paper: !colorMode ? "#fff " : "#121212",
            paperChat: !colorMode ? "#F9FAFB" : "#353535",
            paperOutlined: !colorMode ? "#fff " : "#252525",
            paperNotification: !colorMode ? "#f1f1f1" : "#1d2021",
            lexical: !colorMode ? "#ffffff" : "#1f2223",
            lexicalColumn: !colorMode ? "#ffffff" : "#181a1b",
          },
          chip: {
            pending: !colorMode ? "#E83750" : "#A61328",
            inProgress: !colorMode ? "#ED6C02" : "#BE5602",
            completed: !colorMode ? "#2E7D32" : "#387F4D",
            waitingReview: !colorMode ? "#0288D1" : "#077CB1",
            waitingReturn: !colorMode ? "#5B2E87" : "#502876",
            arquive: !colorMode ? "#777777" : "#424242",
          },

          selectionItem: {
            selected: !colorMode ? "#rgba(91, 46, 135, 0.2)" : "#2D1F3B",
          },
        },
        components: colorMode && {
          MuiButton: {
            styleOverrides: {
              containedPrimary: { backgroundColor: "#5B2E87" },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: { backgroundColor: "#000000" },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: !colorMode ? "#5B2E87" : "#5B2E87",
              },
            },
          },
        },
      }),
    [colorMode]
  );
  return (
    <GlobalStateProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <BrowserRouter>
            <Routes>
              {/* <Route path="*" element={<Navigate replace to="/wiki/public" />} /> */}
              <Route
                element={
                  <LayoutPublica

                  /* colorMode={colorMode}
                    setColorMode={setColorMode} */
                  />
                }
              >
                <Route path="*" element={<Navigate replace to="/public" />} />
                <Route
                  path="/public"
                  element={<WikiOut /* socket={socket} */ />}
                />
                <Route
                  path="/public/:id"
                  element={<WikiOut /* socket={socket} */ />}
                />
              </Route>
              <Route
                path="/login"
                element={<Login setCheckLogin={setCheckLogin} />}
              />

              <Route
                path="/private"
                element={
                  <Layout socket={socket} setCheckLogin={setCheckLogin} />
                }
              >
                <Route
                  path="/private/:id"
                  element={<WikiEditable socket={socket} />}
                />
                <Route
                  path="/private"
                  element={<WikiEditable socket={socket} />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </GlobalStateProvider>
  );
}

export default App;
