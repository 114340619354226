import { Add, Search } from "@mui/icons-material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  Box,
  InputAdornment,
  ListItemText,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
  Breadcrumbs,
  Button,
  Divider,
  Snackbar,
  Alert,
  Autocomplete,
  LinearProgress,
  Fade,
  IconButton,
  Drawer,
  useMediaQuery,
  Skeleton,
} from "@mui/material";

import { TreeView } from "@mui/x-tree-view";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import PlaygroundAppEditable from "../../componentes/lexicalEditable/PlaygroundApp";
import PlaygroundAppPublic from "../../componentes/lexicalPublic/PlaygroundApp";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreateArtigoModal from "./createArtigoModal";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import EditArtigoModal from "./editArtigoModal";
import DeleteArtigoModal from "./deleteArtigoModal";
import AlertArtigoSaveModal from "./alertArtigoSaveModal";
import WikiItem from "../../componentes/wikiItem";
import BotaoBackAndNext from "../../componentes/botaoBackAndNext";
import imagemLogoRodape from "../../assets/logo4.png";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import { useGlobalState } from "../layoutPublica/GlobalStateContext";

const styles = {
  container: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    backgroundColor: "background.lexical",
  },
  columnPaper: {
    display: "flex",
    position: "sticky",
    top: "80px",
    width: "350px",
    minWidth: "350px",
    padding: "22px 20px 22px 20px",
    alignItems: "left",
    flexDirection: "column",
    gap: "22px",
    backgroundColor: "background.lexicalColumn",
    height: "calc(100vh - 80px)",
    //borderRight: 1,
    //borderColor: "divider",
    overflowY: "auto",
    overflowX: "hidden",
  },
  columnPaperMobile: {
    display: "flex",
    position: "sticky",
    top: "0px",
    width: "255px",
    minWidth: "255px",
    padding: "22px 20px 22px 20px",
    alignItems: "left",
    flexDirection: "column",
    gap: "22px",
    backgroundColor: "background.lexicalColumn",
    height: "calc(100vh - 80px)",
    //borderRight: 1,
    //borderColor: "divider",
    overflowY: "auto",
    overflowX: "hidden",
  },
  estiloTreeItem: {
    "& .MuiTreeItem-iconContainer": {
      marginLeft: "6px",
    },

    "& .MuiTreeItem-content:hover": {
      backgroundColor: "rgba(91, 46, 135, 0.1) !important",
      borderRadius: "6px",
    },

    "& .MuiTreeItem-content.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& .MuiTreeItem-content.Mui-selected.Mui-selected": {
      backgroundColor: "rgba(91, 46, 135, 0.3) !important",
      borderRadius: "6px",
    },
    "& .MuiTreeItem-content.Mui-selected.Mui-focused": {
      backgroundColor: "transparent",
    },
  },
  boxConteudoGerado: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  boxBreadcrumbs: {
    display: "flex",
    width: "1143px",
    minHeight: "40px",
    alignItems: "center",
    justifyContent: "flex-start",
    mt: "26px ",
    mb: "10px",
  },
  boxConteudo: {
    display: "block",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    minWidth: "800px",
    height: "100%",
    padding: "0 310px 40px 60px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    maxWidth: "calc(100vw - 50px - 315px)",
  },
  boxConteudoMobile: {
    display: "block",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    minWidth: "90%",
    height: "100%",
    padding: "0px 0px 40px 20px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    maxWidth: "calc(100vw - 50px - 315px)",
  },
  boxLexical: {
    display: "flex",
    margin: "0 auto 2% auto",
    borderRadius: "10px",
  },
  boxIndice: {
    width: "100%",
    display: "flex",
    margin: "0 auto 2% auto",
    borderRadius: "10px",
  },

  textButton: {
    maxWidth: "300px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  boxOpcoesMenuStatus: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  boxRodape: {
    display: "flex",
    width: "100%",
    height: "50px",
    alignItems: "center",
    justifyContent: "flex-end",
    mt: "2px",
  },
  boxImageRodape: {
    display: "flex",
    width: "70px",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default function WikiEditable(params) {
  const { socket } = params;
  const [snackActive, setSnackActive] = useState(false);
  const [artigos, setArtigos] = useState([]);
  const [itemIdSave, setItemIdSave] = useState("");
  const [conteudo, setConteudo] = useState("");
  const [relacionamentoPai, setRelacionamentoPai] = useState([]);
  const [relacionamentoFilho, setRelacionamentoFilho] = useState([]);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [isPai, setIsPai] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPageConteudo, setLoadingPageConteudo] = useState(false);
  const [loadingPageIndice, setLoadingPageIndice] = useState(false);
  const [sockerFlag, setSocketFlag] = useState(true);

  const [expandedNodes, setExpandedNodes] = useState([]);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalAlertSave, setOpenModalAlertSave] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [menuId, setMenuId] = useState("");
  const [tituloRenomearMenu, setTituloRenomearMenu] = useState("");

  const [conteudoMenu, setConteudoMenu] = useState(false);
  const [arrayCascata, setArrayCascata] = useState("");
  const [nodePai, setNodePai] = useState([]);
  const [opcoesPesquisar, setOpcoesPesquisar] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loadingArtigos, setLoadingArtigos] = useState(false);
  const [valuePesquisa, setValuePesquisa] = useState("");
  const [editable, setEditable] = useState(false);
  const [valorItemSave, setValorItemSave] = useState(false);
  const [valorModalAlertSave, setValorModalAlertSave] = useState([]);
  const isMobile = useMediaQuery("(max-width:1160px)");
  const { listActive, setListActive } = useGlobalState();
  const columnPaperMobileRef = useRef(null);
  const { id } = useParams();
  const logsLoginId = localStorage?.getItem("logs")
    ? JSON.parse(localStorage?.getItem("logs")).id
    : "";
  const navigate = useNavigate();

  const memoPlayground = useMemo(
    () =>
      itemIdSave ? (
        <>
          {" "}
          {editable ? (
            <PlaygroundAppEditable
              key={Math.random()}
              exibirConteudo={conteudo?.conteudo || jsonInicial}
              itemIdSave={id}
              socket={socket}
              setValorItemSave={setValorItemSave}
              snackActiveSucess={snackActiveSucess}
            />
          ) : (
            <PlaygroundAppPublic
              key={Math.random()}
              exibirConteudo={conteudo?.conteudo || jsonInicial}
              itemIdSave={id}
              snackActiveSucess={snackActiveSucess}
            />
          )}
        </>
      ) : (
        ""
      ),
    [conteudo, itemIdSave]
  );

  const myTheme = useTheme();

  //secção de useEffect's
  useEffect(() => {
    if (logsLoginId === "") {
      navigate("/");
    }
  }, [logsLoginId]);

  useEffect(() => {
    if (sockerFlag) {
      callInitAPIs();
    }
  }, [socket]);

  useEffect(() => {
    navigateToArticle(id || artigos[0]?.id);
  }, [artigos]);

  //secção de apis
  //chama a api para obter os artigos

  async function callInitAPIs() {
    let admin = false;
    let perfil = localStorage.getItem("2");
    perfil = JSON.parse(perfil);
    if (perfil.codigoPermissao) {
      if (
        perfil.codigoPermissao &&
        (perfil.codigoPermissao.indexOf("adm001") !== -1 ||
          perfil.codigoPermissao.indexOf("adm002") !== -1 ||
          perfil.codigoPermissao.indexOf("wiki001") !== -1)
      ) {
        admin = true;
        setEditable(true);
      }
    }

    const response = await Promise.all([
      handleApiGetArtigos(admin),
      handleGetAllArtigos(admin),
    ]);
    if (response[0] && response[1]) {
      setArtigos(response[0]);
      setOpcoesPesquisar(response[1]);
    }
    setSocketFlag(false);
  }

  async function handleGetAllArtigos(admin) {
    try {
      setLoadingArtigos(true);
      const message = { req: "get", usuarioId: usuarioId() };
      if (admin) {
        const response = await new Promise((resolve, reject) => {
          socket.emit("findManyWikiArtigosResearch", message, (response) => {
            if (response.status === 403) {
              console.error(response.error);
              resolve(null);
            } else if (response.status !== 200) {
              reject(new Error("Erro ao obter dados Socket"));
            } else {
              resolve(response.data);
            }
          });
        });
        return response;
      } else {
        const response = await new Promise((resolve, reject) => {
          socket.emit(
            "findManyWikiArtigosResearchLimited",
            message,
            (response) => {
              if (response.status !== 200) {
                reject(new Error("Erro ao obter dados Socket"));
              } else {
                resolve(response.data);
              }
            }
          );
        });
        return response;
      }
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingArtigos(false);
    }
  }

  async function handleApiGetArtigos(admin) {
    try {
      const message = { req: "get", usuarioId: usuarioId() };
      if (admin) {
        const response = await new Promise((resolve, reject) => {
          socket.emit("findManyWikiArtigos", message, (response) => {
            if (response.status === 403) {
              console.error(response.error);
              resolve(null);
            } else if (response.status !== 200) {
              reject(new Error("Erro ao obter dados Socket"));
            } else {
              resolve(response.data);
            }
          });
        });
        return response;
      } else {
        const response = await new Promise((resolve, reject) => {
          socket.emit("findManyWikiArtigosLimited", message, (response) => {
            if (response.status !== 200) {
              reject(new Error("Erro ao obter dados Socket"));
            } else {
              resolve(response.data);
            }
          });
        });
        return response;
      }
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    }
  }

  //chama a api para obter os artigos por id
  async function handleApiGetArtigosById(id, clickTreeView) {
    try {
      setLoadingPage(true);
      setLoadingPageConteudo(true);
      const message = {
        id,
        usuarioId: usuarioId(),
      };

      if (editable) {
        socket.emit("findConteudoWikiById", message, (response) => {
          if (response.status === 403) {
            console.error(response.error);
          } else if (response.status !== 200) {
            console.error("Erro na requisição:", response.error);
          } else {
            const data = response.data;

            setConteudo(data);
            setRelacionamentoPai(data.relacionamentoPai);

            setRelacionamentoFilho(data.relacionamentoFilho);

            //Selecionar o item clicado
            let id = data.id;
            handleSelectedItemsChange(null, [id]);

            let paisId = data.relacionamentoPai?.map((pai) => pai.id).reverse();

            if (!(data?.id === conteudo?.id) && !clickTreeView) {
              handleToggle(null, [id, ...paisId]);
            }

            if (isMobile && clickTreeView && !(data?.id === conteudo?.id)) {
              setListActive(false);
            }

            navigate(`/private/${id}`);
            setLoadingPage(false);
            setLoadingPageConteudo(false);
          }
        });
      } else {
        socket.emit("findConteudoWikiByIdLimited", message, (response) => {
          if (response.status !== 200) {
            console.error("Erro na requisição:", response.error);
          } else {
            const data = response.data;

            setConteudo(data);
            setRelacionamentoPai(data.relacionamentoPai);

            setRelacionamentoFilho(data.relacionamentoFilho);

            //Selecionar o item clicado
            let id = data.id;
            handleSelectedItemsChange(null, [id]);

            let paisId = data.relacionamentoPai?.map((pai) => pai.id).reverse();

            if (!(data?.id === conteudo?.id) && !clickTreeView) {
              handleToggle(null, [id, ...paisId]);
            }

            if (isMobile && clickTreeView && !(data?.id === conteudo?.id)) {
              setListActive(false);
            }

            navigate(`/private/${id}`);
            setLoadingPage(false);
            setLoadingPageConteudo(false);
          }
        });
      }
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingPage(false);
      setLoadingPageConteudo(false);
    }
  }

  //chama a api para obter os relacionamentos dos artigos por id
  async function handleApiGetRelacionamentoArtigosById(id, clickTreeView) {
    try {
      setLoadingPage(true);
      setLoadingPageIndice(true);
      let response;
      const message = {
        id,
        usuarioId: usuarioId(),
      };

      if (editable) {
        response = await new Promise((resolve, reject) => {
          socket.emit("findRelacionamentoArtigoWiki", message, (response) => {
            if (response.status === 403) {
              console.error(response.error);
            } else if (response.status !== 200) {
              reject(new Error("Erro ao obter dados Socket"));
            } else {
              resolve(response);
            }
          });
        });
      } else {
        response = await new Promise((resolve, reject) => {
          socket.emit(
            "findRelacionamentoArtigoWikiLimited",
            message,
            (response) => {
              if (response.status !== 200) {
                reject(new Error("Erro ao obter dados Socket"));
              } else {
                resolve(response);
              }
            }
          );
        });
      }

      if (response.status !== 200) {
        throw new Error("Erro ao obter dados da API");
      } else {
        const data = response.data;
        setConteudo(data);
        setRelacionamentoPai(data.relacionamentoPai);
        setRelacionamentoFilho(data.relacionamentoFilho);
        let id = data.id;
        handleSelectedItemsChange(null, [id]);

        let paisId =
          data.relacionamentoPai?.map((pai) => pai.id).reverse() || [];

        if (!(data?.id === conteudo?.id) && !clickTreeView) {
          handleToggle(null, [id, ...paisId]);
        }

        if (isMobile && clickTreeView && !(data?.id === conteudo?.id)) {
          setListActive(false);
        }

        navigate(`/private/${id}`);
        setLoadingPage(false);
        setLoadingPageIndice(false);
      }
    } catch (error) {
      console.error("Erro na requisição:", error.message);
    } finally {
      setLoadingPage(false);
      setLoadingPageIndice(false);
    }
  }

  //Secção de funções

  const usuarioId = () => {
    let result = localStorage.getItem("2");
    result = JSON.parse(result);
    if (result) {
      //const codigos = ;
      return result.usuarioId;
    } else {
      return "";
    }
  };

  //Fechar snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackActive(false);
  };

  //Ativar Snackbar de sucesso ao salvar
  function snackActiveSucess() {
    setSnackActive(true);
  }
  const navigateToArticle = async (articleId) => {
    if (!articleId) return;

    const article = findArticleByIdRecursive(articleId, artigos);
    if (!article) return;

    handleTreeItemClick(articleId, article?.possuiConteudo);
  };

  async function handleOpenModalAlertSave(
    id,
    possuiConteudo,
    clickTreeView,
    conteudoAbertoId
  ) {
    setOpenModalAlertSave(true);
    setValorModalAlertSave([
      id,
      possuiConteudo,
      clickTreeView,
      conteudoAbertoId,
    ]);
  }

  const handleTreeItemClick = (id, possuiConteudo, clickTreeView) => {
    if (!valorItemSave) {
      if (possuiConteudo === true) {
        handleApiGetArtigosById(id, clickTreeView);
        setItemIdSave(id);
      } else {
        setItemIdSave("");
        setConteudo("");
        handleApiGetRelacionamentoArtigosById(id, clickTreeView);
      }
    } else {
      handleOpenModalAlertSave(id, possuiConteudo, clickTreeView, conteudo.id);
    }
  };

  const handleTreeItemClickModalAlert = (id, possuiConteudo, clickTreeView) => {
    if (possuiConteudo === true) {
      handleApiGetArtigosById(id, clickTreeView);
      setItemIdSave(id);
    } else {
      setItemIdSave("");
      setConteudo("");
      handleApiGetRelacionamentoArtigosById(id, clickTreeView);
    }
    setValorItemSave(false);
  };

  // Procura o ID de um artigo na árvore de artigos de forma recursiva
  const findArticleByIdRecursive = (id, articles) => {
    for (const article of articles) {
      // Verifica se o artigo atual possui o ID desejado
      if (article.id === id) {
        return article;
      }
      // Verifica se o artigo atual possui filhos
      if (article.artigosFilho && article.artigosFilho.length > 0) {
        // Realiza uma busca recursiva nos filhos
        const foundArticle = findArticleByIdRecursive(id, article.artigosFilho);
        // Se o artigo for encontrado nos filhos, retorna o resultado da busca
        if (foundArticle) {
          return foundArticle;
        }
      }
    }
    // Se nenhum artigo for encontrado, retorna null
    return null;
  };

  const filhosIdsCascata = (node) => {
    let ids = [];

    // Verifica se o nó atual tem um ID e o adiciona à lista de IDs
    if (node.id) {
      ids.push(node.id);
    }

    // Verifica se o nó atual tem filhos e chama a função recursivamente para cada filho
    if (node.artigosFilho && node.artigosFilho.length > 0) {
      node.artigosFilho.forEach((child) => {
        // Concatena os IDs dos filhos ao resultado da função recursiva
        ids = ids.concat(filhosIdsCascata(child));
      });
    }

    return ids;
  };

  // Função para encontrar o pai de um nó na árvore de artigos
  const findParentNodeInTree = (parentId, tree) => {
    // Percorre todos os nós da árvore
    for (const node of tree) {
      // Verifica se o nó atual é o pai do nó procurado
      if (
        node.artigosFilho &&
        node.artigosFilho.some((child) => child.id === parentId)
      ) {
        return node; // Retorna o nó pai encontrado
      }
      // Se não encontrar o pai no nó atual, verifica nos filhos (se houver)
      if (node.artigosFilho && node.artigosFilho.length > 0) {
        const parentNode = findParentNodeInTree(parentId, node.artigosFilho); // Chamada recursiva nos filhos
        if (parentNode) {
          return parentNode; // Retorna o nó pai encontrado nos filhos
        }
      }
    }
    return null; // Retorna null se não encontrar o pai
  };

  const handleToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleSelectedItemsChange = (event, ids) => {
    if (!Array.isArray(ids)) ids = [ids];

    setSelectedItems(ids);
  };

  const renderTree = (nodes) => (
    <Fragment key={nodes.id}>
      <WikiItem
        socket={socket}
        usuarioId={usuarioId()}
        nodes={nodes}
        renderTree={renderTree}
        nodePai={nodePai}
        setNodePai={setNodePai}
        setArrayCascata={setArrayCascata}
        arrayCascata={arrayCascata}
        filhosIdsCascata={filhosIdsCascata}
        artigos={artigos}
        setMenuId={setMenuId}
        findParentNodeInTree={findParentNodeInTree}
        setIsPai={setIsPai}
        setTituloRenomearMenu={setTituloRenomearMenu}
        setConteudoMenu={setConteudoMenu}
        setOpenModalCreate={setOpenModalCreate}
        setOpenModalEdit={setOpenModalEdit}
        setOpenModalDelete={setOpenModalDelete}
        callInitAPIs={callInitAPIs}
        handleTreeItemClick={handleTreeItemClick}
        handleToggle={handleToggle}
        expandedNodes={expandedNodes}
        logsLoginId={logsLoginId}
        editable={editable}
        conteudoAbertoId={conteudo.id}
      />
    </Fragment>
  );

  function handleClickLink(event) {
    event.preventDefault();
  }

  const handleChangeOptions = async (event, newValue) => {
    if (newValue) {
      handleTreeItemClick(newValue.id, newValue.possuiConteudo);
    }
    setValuePesquisa("");
    setSelectedOption(newValue);
  };

  const StyledLink = styled(Link)(({ theme, aberto }) => ({
    color: aberto ? "#5a2e87f5" : myTheme.palette.chip.arquive,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: "1rem",
    fontWeight: aberto ? 600 : 500,
    overflowWrap: "break-word",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    hyphens: "auto",
  }));

  const BreadcrumbsTree = () => {
    return (
      <div role="presentation" onClick={handleClickLink}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {relacionamentoPai?.map(({ id, titulo, possuiConteudo }, index) => {
            return (
              <StyledLink
                key={index}
                underline="hover"
                color="inherit"
                onClick={() => {
                  handleTreeItemClick(id, possuiConteudo);
                }}
                aberto={false}
              >
                {titulo}
              </StyledLink>
            );
          })}
          {conteudo && (
            <StyledLink
              key={conteudo.id}
              underline="hover"
              color="inherit"
              aberto={true}
            >
              {conteudo.titulo}
            </StyledLink>
          )}
        </Breadcrumbs>
      </div>
    );
  };

  return (
    <Box
      sx={{
        ...styles.container,
        mt: isMobile ? "-24px" : "",
        minHeight: isMobile ? "calc(100vh - 62px)" : "calc(100vh - 80px)",
      }}
    >
      {isMobile ? (
        <Drawer
          anchor="left"
          open={listActive}
          onClose={() => {
            setListActive(false);
          }}
        >
          <Paper
            ref={columnPaperMobileRef}
            sx={styles.columnPaperMobile}
            variant="outlined"
          >
            <Autocomplete
              value={selectedOption}
              onChange={handleChangeOptions}
              options={opcoesPesquisar}
              getOptionLabel={(option) => option.titulo}
              noOptionsText="Nenhum resultado"
              onBlur={() => setValuePesquisa("")}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    placeholder="Pesquisar"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      setValuePesquisa(event.target.value);
                    }}
                  />
                  <Fade in={loadingArtigos}>
                    <LinearProgress />
                  </Fade>
                </>
              )}
              PaperComponent={(props) => (
                <>
                  {valuePesquisa.length >= 2 && (
                    <Paper
                      {...props}
                      style={{
                        fontSize: "20px",
                      }}
                    />
                  )}
                </>
              )}
            />
            {editable && (
              <Button
                onClick={(event) => {
                  setOpenModalCreate(true);
                  setIsPai(true);
                }}
                variant="contained"
                startIcon={<Add />}
              >
                Criar artigo
              </Button>
            )}

            <TreeView
              expanded={expandedNodes}
              selected={selectedItems}
              sx={styles.estiloTreeItem}
            >
              {artigos?.map((node) => renderTree(node))}
            </TreeView>
          </Paper>
        </Drawer>
      ) : (
        <Paper sx={styles.columnPaper} variant="outlined">
          <Autocomplete
            value={selectedOption}
            onChange={handleChangeOptions}
            options={opcoesPesquisar}
            getOptionLabel={(option) => option.titulo}
            noOptionsText="Nenhum resultado"
            onBlur={() => setValuePesquisa("")}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  placeholder="Pesquisar"
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => {
                    setValuePesquisa(event.target.value);
                  }}
                />
                <Fade in={loadingArtigos}>
                  <LinearProgress />
                </Fade>
              </>
            )}
            PaperComponent={(props) => (
              <>
                {valuePesquisa.length >= 2 && (
                  <Paper
                    {...props}
                    style={{
                      fontSize: "20px",
                    }}
                  />
                )}
              </>
            )}
          />
          {editable && (
            <Button
              onClick={(event) => {
                setOpenModalCreate(true);
                setIsPai(true);
              }}
              variant="contained"
              startIcon={<Add />}
            >
              Criar artigo
            </Button>
          )}

          <TreeView
            expanded={expandedNodes}
            selected={selectedItems}
            sx={styles.estiloTreeItem}
          >
            {artigos?.map((node) => renderTree(node))}
          </TreeView>
        </Paper>
      )}

      <Box sx={styles.boxConteudoGerado}>
        <Box
          sx={{
            ...styles.boxBreadcrumbs,
            width: isMobile ? "90%" : "1143px",
            ml: isMobile ? "20px" : "60px",
          }}
        >
          {conteudo !== "" && !loadingPage && <BreadcrumbsTree />}
          {loadingPage && (
            <Skeleton variant="rounded" width={"100%"} height={30} />
          )}
        </Box>
        <Box sx={isMobile ? styles.boxConteudoMobile : styles.boxConteudo}>
          {conteudo?.conteudo && !loadingPageConteudo && (
            <Box
              sx={{
                ...styles.boxLexical,
                border: editable ? "1px solid #ccc" : "",
              }}
            >
              {memoPlayground}
            </Box>
          )}
          {loadingPageConteudo && (
            <Skeleton variant="rounded" width={"100%"} height={300} />
          )}
          {!conteudo?.conteudo && conteudo !== "" && !loadingPageIndice && (
            <Box sx={styles.boxIndice}>
              <Box sx={{ minHeight: "300px", width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: "1.6rem",
                    fontWeight: 400,
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    hyphens: "auto",
                  }}
                >
                  {conteudo.titulo}
                </Typography>
                <Divider />
                <Typography
                  sx={{ textAlign: "justify", mt: 2, fontSize: "18px" }}
                  variant="body1"
                >
                  Esta seção fornece uma visão geral dos indices contidos nesse
                  tópico e ajudará você a se orientar nas opções disponíveis.
                </Typography>
                {relacionamentoFilho?.length > 0 && (
                  <Stack gap={0} mt={3}>
                    {relacionamentoFilho?.map((link) => {
                      return (
                        <ListItem
                          key={link.id}
                          disablePadding
                          onClick={() => {
                            handleTreeItemClick(link.id, link.possuiConteudo);
                          }}
                          sx={{ width: "fit-content" }}
                        >
                          <li />
                          <ListItemText>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "primary.main",
                                fontSize: "1.2rem",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                                overflowWrap: "break-word",
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                wordBreak: "break-word",
                                hyphens: "auto",
                              }}
                            >
                              {link.titulo}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      );
                    })}
                  </Stack>
                )}
              </Box>
            </Box>
          )}
          {loadingPageIndice && (
            <Box sx={styles.boxIndice}>
              <Box sx={{ minHeight: "300px", width: "100%" }}>
                {loadingPageIndice && (
                  <Skeleton variant="rounded" width={"40%"} height={34} />
                )}
                <Divider sx={{ mb: "20px", mt: "4px" }} />
                {loadingPageIndice && (
                  <Skeleton variant="rounded" width={"85%"} height={24} />
                )}
                {loadingPageIndice && (
                  <Stack gap={4} mt={4}>
                    {loadingPageIndice && (
                      <Skeleton variant="rounded" width={"26%"} height={22} />
                    )}
                    {loadingPageIndice && (
                      <Skeleton variant="rounded" width={"36%"} height={22} />
                    )}
                    {loadingPageIndice && (
                      <Skeleton variant="rounded" width={"22%"} height={22} />
                    )}
                    {loadingPageIndice && (
                      <Skeleton variant="rounded" width={"30%"} height={22} />
                    )}
                  </Stack>
                )}
              </Box>
            </Box>
          )}

          {conteudo !== "" && !loadingPage && (
            <BotaoBackAndNext
              relacionamentoPai={relacionamentoPai}
              relacionamentoFilho={relacionamentoFilho}
              conteudo={conteudo}
              artigos={artigos}
              findParentNodeInTree={findParentNodeInTree}
              handleTreeItemClick={handleTreeItemClick}
            />
          )}
          {loadingPage && (
            <Skeleton
              variant="rounded"
              width={"100%"}
              height={70}
              sx={{ mt: "16px" }}
            />
          )}
          {conteudo !== "" && !loadingPage && (
            <Stack
              width="100%"
              direction="row"
              justifyContent="end"
              alignItems="center"
              gap={2}
            >
              <Box>
                <IconButton
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/mwsolucoesmonitoramento/"
                    );
                  }}
                >
                  <FacebookIcon
                    sx={{ fontSize: "25px", color: "rgb(153, 167, 187)" }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/company/mw-solucoes/"
                    );
                  }}
                >
                  <LinkedInIcon
                    sx={{ fontSize: "25px", color: "rgb(153, 167, 187)" }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open("https://www.instagram.com/mwsolucoesltda/");
                  }}
                >
                  <InstagramIcon
                    sx={{ fontSize: "25px", color: "rgb(153, 167, 187)" }}
                  />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.open("https://home.mw-solucoes.com/");
                  }}
                >
                  <LanguageIcon
                    sx={{ fontSize: "25px", color: "rgb(153, 167, 187)" }}
                  />
                </IconButton>
              </Box>
              <img
                src={imagemLogoRodape}
                style={{ width: "auto", height: "60px" }}
              />
            </Stack>
          )}
          {loadingPage && (
            <Stack
              width="100%"
              direction="row"
              justifyContent="end"
              alignItems="center"
            >
              <Skeleton
                variant="rounded"
                width={"250px"}
                height={40}
                sx={{ mt: "12px" }}
              />
            </Stack>
          )}
        </Box>
      </Box>

      <CreateArtigoModal
        socket={socket}
        usuarioId={usuarioId()}
        paiId={menuId}
        paiValorConteudo={conteudoMenu}
        isPai={isPai}
        openModalCreate={openModalCreate}
        setOpenModalCreate={setOpenModalCreate}
        logsLoginId={logsLoginId}
        callInitAPIs={callInitAPIs}
        handleTreeItemClick={handleTreeItemClick}
      />
      <EditArtigoModal
        socket={socket}
        usuarioId={usuarioId()}
        id={menuId}
        conteudoAbertoId={conteudo.id}
        valorConteudoAberto={conteudoMenu}
        relacionamentoFilho={relacionamentoFilho}
        openModalEdit={openModalEdit}
        setOpenModalEdit={setOpenModalEdit}
        titulo={tituloRenomearMenu}
        logsLoginId={logsLoginId}
        callInitAPIs={callInitAPIs}
        handleTreeItemClick={handleTreeItemClick}
      />
      <DeleteArtigoModal
        socket={socket}
        usuarioId={usuarioId()}
        id={arrayCascata}
        conteudoAbertoId={conteudo.id}
        relacionamentoFilho={relacionamentoFilho}
        setConteudo={setConteudo}
        valorConteudoAberto={conteudoMenu}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        logsLoginId={logsLoginId}
        callInitAPIs={callInitAPIs}
        handleTreeItemClick={handleTreeItemClick}
        handleToggle={handleToggle}
      />
      <AlertArtigoSaveModal
        openModalAlertSave={openModalAlertSave}
        setOpenModalAlertSave={setOpenModalAlertSave}
        valorModalAlertSave={valorModalAlertSave}
        handleTreeItemClickModalAlert={handleTreeItemClickModalAlert}
        handleSelectedItemsChange={handleSelectedItemsChange}
      />
      <Snackbar
        open={snackActive}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        style={{ bottom: "1%" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Conteúdo salvo com sucesso!
        </Alert>
      </Snackbar>
    </Box>
  );
}

const jsonInicial = {
  editorState: {
    root: {
      children: [
        {
          children: [
            {
              detail: 0,
              format: 0,
              mode: "normal",
              style: "",
              text: "",
              type: "text",
              version: 1,
            },
          ],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  },
  lastSaved: Date.now(),
  source: "Wiki",
  version: "0.12.6",
};
